<template>
  <div class="drushim">
      <div class="drushim-header">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
                type="text"
                v-model="search"
                placeholder="חיפוש משרה"
                style="width:100%"
            />
          </span>
      </div>
      <div v-if="filter_job_by_search.length==0" class="no-jobs">
              <h1>לא נמצאו מודעות</h1>
              <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fno-job_375x375.png?alt=media&token=d105eaff-cd6f-4ea2-9379-6ffce3261d96">
      </div>
      <div v-if="filter_job_by_search.length>0" class="drushim-wrapper">
          <template v-for="job in filter_job_by_search" :key="job.id">
              <Job :ad="job"/>
          </template>
      </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import {projectFirestore} from '../firebase/config'
import InputText from "primevue/inputtext";
import Job from '../components/Drushim/Job.vue'
export default {
    components:{Job,InputText},
    setup(){

        const search = ref('')
        const jobs = ref([])
        const filter_job_by_search = ref(computed(()=>{
            if(search.value=='') return jobs.value
            return jobs.value.filter(job=>{
                if(new Date(job.createdAt.seconds * 1000).toLocaleString('he').includes(search.value)) return job
                if(job.job_header.includes(search.value)) return job
            })
        }))
        const get_jobs_from_db =async()=>{
           const docs =  await projectFirestore.collection('Jobs').where('status','==','new').orderBy('createdAt','desc').get()
           jobs.value = docs.docs.map(doc=>doc.data())
        }

        onMounted(async()=>{
            await get_jobs_from_db()
        })
        return{jobs,search,filter_job_by_search}
    }
}
</script>

<style scoped>
    .drushim{
        width: 100%;
        height: 100%;
        color: #fff;
        overflow:hidden;
    }
    .drushim-header{
        margin:0 auto;
        width: 70%;
        height: 10%;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
    .drushim-wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-auto-rows: 400px;
        grid-gap: 5px;
        padding: 10px;
        margin:0 auto;
        width: 70%;
        height: 90%;
        overflow:hidden;
        overflow-y: auto;
    }
    .no-jobs{
        margin:0 auto;
        width: 70%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow:hidden;
    }
   
    @media screen and (max-width: 600px) {
        .drushim-wrapper{
            width: 100%;
            grid-template-columns: repeat(1,1fr);
            grid-gap: 10px;
        }
        .no-jobs img{
            width: 200px;
        }
    }
</style>