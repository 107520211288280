<template>
  <div @click="show_details=!show_details" class="job-container">
    <div class="job-image" :style="{ backgroundImage: 'url('+ad.image.url+')' }">  
    </div>
    <div class="job-info">
        <div>
            <p>{{ad.job_header}}</p>
        </div>
        <div>
             <p>פורסם בתאריך: {{ad.createdAt.toDate().toLocaleDateString('he')}}</p>
        </div>
    </div>
  </div>
  <el-dialog
    v-model="show_details"
    :width="dialog_width"
    :fullscreen="full_screen"
    top="5vh"
  >
    <div class="main-details">
        <div class="details">
            <div class="detials-field">
                <h1>שם המשרה</h1>
                <p>{{ad.job_header}}</p>
            </div>
            <div class="detials-field">
                <h1>תפקיד</h1>
                <p>{{ad.role}}</p>
            </div>
            <div class="detials-field">
                <h1>פרטי המשרה</h1>
                <p>{{ad.job_details}}</p>
            </div>
            <div class="detials-field">
               <h1>דרישות התפקיד</h1>
                <div class="requirements">
                    <template v-for="(req,index) in ad.requirements" :key="index">
                        <p class="requirement">{{req.requirement}}</p>
                    </template>
                </div>
            </div>

            <div @click="show_details=!show_details" class="back-btn">
              <i class="material-icons">arrow_back</i>
            </div>
        </div>
        
        <div @click="push_to('drushim_landing_page')" class="apply_btn">
            <p>הגשת מועמדות</p>
        </div>
    </div>
    
  </el-dialog>
   
</template>

<script>
import { ref } from '@vue/reactivity'
import {onMounted, onUnmounted } from '@vue/runtime-core'
import router from '../../router'



export default {
    props:['ad'],
    setup(props){
        const full_screen = ref(false)
        const dialog_width=ref('40%')
        const show_details = ref(false)

        const push_to = (path_name)=>{
            router.push({name:path_name, params:{jobId:props.ad.id}});
        }

        

        window.addEventListener('resize',()=>{
           if(window.innerWidth<=1024){
               full_screen.value = true
           }else{
               full_screen.value = false
           }
           if(window.innerHeight<=800){
               document.documentElement.style.setProperty('--screen-height', `${window.innerHeight - 100}px`);
           }else{
               document.documentElement.style.setProperty('--screen-height', '700px');
           }
        })

        onMounted(()=>{
            const jobImage = document.querySelector('.job-image')
            const resize_ob = new ResizeObserver(function(entries) {
                let rect = entries[0].contentRect;
                const jobInfo = document.querySelectorAll('.job-info')
                jobInfo.forEach(el=>el.style.setProperty('height', `calc(400px - ${rect.y}px)`))
            })
            resize_ob.observe(jobImage);

           if(window.innerWidth<=1024){
              full_screen.value = true
           }else{
              full_screen.value = false
           }
           if(window.innerHeight<=800){
               document.documentElement.style.setProperty('--screen-height', `${window.innerHeight - 100}px`);
           }else{
               document.documentElement.style.setProperty('--screen-height', '700px');
           }
        })
        onUnmounted(()=>{
            window.removeEventListener('resize',()=>{
                if(window.innerWidth<=800){
                    full_screen.value = true
                }else{
                    full_screen.value = false
                }
                if(window.innerHeight<=1024){
                    document.documentElement.style.setProperty('--screen-height', `${window.innerHeight - 100}px`);
                }else{
                    document.documentElement.style.setProperty('--screen-height', '700px');
                }
            })
        })
        return{
            push_to,
            full_screen,
            show_details,
            dialog_width
        }
    }
}
</script>

<style scoped>
    :root{
        --screen-height:700px
    }
    .job-container{
        border-radius: 10px;
        width: 100%;
        background: var(--secondary);
        cursor: pointer;
    }
    .job-image{
        width: 100%;
        padding-top: 75%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 95%;
    }
    .job-info{
        width: 100%;
        color: #fff;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }
    .job-info > div{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }
  
    .main-details{
        position: relative;
        height: var(--screen-height);
        width: 100%;
        overflow:hidden;
       
    }
    .main-details .back-btn{
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--purple);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        user-select: none;
    }
    .details{ 
        position: relative;
        width: 100%;
        height: 90%;
        overflow:hidden;
        overflow-y: auto;
        line-height: 1.8;
    }
    .detials-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
    
    .details h1{
        font-weight:400;
    }
    .details p{
        white-space: pre-wrap;
        font-size: 18px;
        line-height: normal;
    }
    .details .requirements{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
    }
    .details .requirements .requirement{
        background:var(--purple);
        padding: 2px;
        border-radius: 5px;
        color: #fff;
        margin:5px 2px;
    }
    .apply_btn{
        width: 100%;
        height:10%;
        border:1px solid var(--purple);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
    }
    .apply_btn:hover{
        background: var(--purple);
        color: #fff;
    }
  
  
   

</style>